<template>
  <div class="auth-wrapper auth-v1 px-2 merci-upawlo">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo d-flex align-items-center justify-content-center">
          <b-img
            :src="appLogoImage"
            alt="logo"
            width="150"
          />
        </b-link>

        <b-card-title class="mb-1">
          Bienvenue sur {{ appName }}
        </b-card-title>
        <b-card-text class="mb-2">
          Veuillez renseigner vos identifiants de connexion
        </b-card-text>

        <b-alert
          v-if="errors"
          variant="danger"
          show
        >
          <div class="alert-body">
            <span>{{ errors }}</span>
          </div>
        </b-alert>
        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Identifiant"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small v-if="errors.length > 0" class="text-danger">Identifiant/Email obligatoire</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Mot de passe</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>Mot de passe oublié ?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Mot de passe"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small  v-if="errors.length > 0" class="text-danger">Mot de passe obligatoire</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              <b-spinner
                v-if="loading"
                small
              />
              <span v-if="!loading">Connexion</span>
            </b-button>
          </b-form>
        </validation-observer>

        <div class="divider my-2">
          <div class="divider-text" />
        </div>
        <small class="text-xs">
          Identifiant oublié ? Veuillez prendre contact avec l'administrateur de votre EHPAD
        </small>
        <!-- social button -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
  BAlert,
  BImg,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BImg,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BSpinner,
    BLink,
    BAlert,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    const { appLogoImage, appName } = $themeConfig.app
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      appLogoImage,
      appName,
      loading: false,
      errors: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.loading = true
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            username: this.userEmail,
            password: this.password,
          })
            .then(async response => {
              const userData = response.data
              useJwt.setToken(userData.token)
              useJwt.setRefreshToken(userData.refresh_token)
              useJwt.setOrganisation(userData.data.organisation)
              localStorage.setItem('userData', JSON.stringify(userData.data))

              await this.$http.get('/api/parametres/get').then(res => {
                localStorage.setItem('parameters', JSON.stringify(res.data))
              })

              this.loading = false
              this.$router.replace(getHomeRouteForLoggedInUser('client'))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Bienvenue ${userData.data.prenom} ${userData.data.nom}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Vous êtes maintenant connecté sur votre interface de gestion d\'incidents.',
                    },
                  })
                })
            })
            .catch(error => {
              this.loading = false
              if (error.response.data.message === 'Invalid credentials.') {
                this.errors = 'Mauvais identifiant ou mot de passe.'
              } else {
                this.errors = error.response.data.message
              }
              this.$refs.loginForm.setErrors(error.response.data.message)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
